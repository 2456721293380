.logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.logo-img {
  max-height: 15vh;
}

.bin-image {
  max-width: 25px;
}

.container {
  width: auto;
  max-width: 680px;
  padding: 1rem 1rem;
}

.nested-fieldset {
  padding: 2vw 4vw;
  margin-bottom: 5vh;
  border: 1px solid #ddd;
}

.navLinkSelected {
  border: 10px solid blue;
}

.navLinkButton {
  width: 100%;
}
